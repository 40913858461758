<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 13:23:06
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-20 13:29:10
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\performance\components\TitleCom.vue
 * @Description: 标题
 *
-->
<template>
  <div class="custom-title">
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.custom-title {
  text-align: left;
  padding-left: 18/75rem;
  font-size: 30/75rem;
  font-weight: 800;
  color: #212122;
  line-height: 30/75rem;
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    width: 6/75rem;
    height: 27/75rem;
    background: #21424b;
    position: absolute;
    left: 0;
    top: 2/75rem;
  }
}
</style>
