<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 15:29:46
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-27 18:25:42
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\performance\components\mobile\detail\DetailInfo.vue
 * @Description: 剧目详情
 *
-->

<template>
  <div class="detail-info">
    <div class="detail-body">
      <div id="detail-1" class="detail-box">
        <TitleCom title="剧目简介" />
        <div v-html="detail.descript" class="content"></div>
        <div v-if="detail.video" class="video-box">
          <video
            controlsList="nodownload"
            id="video"
            :src="detail.video"
            controls
          ></video>
        </div>
      </div>
      <div id="detail-2" class="detail-box">
        <TitleCom title="主创人员" />
        <div class="content actor-list">
          <Actor
            v-for="(item, index) in teamMembers"
            :key="index"
            :item="item"
          />
        </div>
      </div>
      <div id="detail-3" class="detail-box">
        <TitleCom title="演员阵容" />
        <div class="content actor-list">
          <Actor
            v-for="(item, index) in actorMembers"
            :key="index"
            :item="item"
            type="actor"
          />
        </div>
      </div>
      <div id="detail-4" class="detail-box">
        <TitleCom title="剧照赏析" />
        <div class="content">
          <el-carousel
            v-if="detail.pictures?.length"
            height="340/75rem"
            :autoplay="false"
            indicator-position="none"
            arrow="always"
            ref="pictureRef"
            @change="changeSwiper"
          >
            <el-carousel-item
              v-for="(item, index) in detail.pictures"
              :key="index"
            >
              <c-image
                :src="item"
                style="width: 100%; height: 100%; border-radius: 8/75rem"
                fit="contain"
              />
            </el-carousel-item>
          </el-carousel>
          <div class="img-list">
            <c-image
              v-for="(item, index) in detail.pictures"
              :key="index"
              :src="item"
              :class="['img-item', { active: swiperIndex === index }]"
              fit="contain"
              @click="handleScroll(index)"
            />
          </div>
        </div>
      </div>
      <div id="detail-5" class="detail-box">
        <TitleCom title="媒体报道" />
        <div class="content">
          <MediaItem
            v-for="item in detail.newsLinks"
            :key="item.id"
            :item="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleCom from "./TitleCom.vue";
import Actor from "./Actor.vue";
import MediaItem from "./MediaItem.vue";
export default {
  components: { TitleCom, Actor, MediaItem },
  props: {
    list: {
      type: Array,
      default: () => [{ id: 1 }, { id: 2 }],
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tabId: "detail-1",
      tabList: [
        { label: "剧目简介", value: 1 },
        { label: "主创人员", value: 2 },
        { label: "演员阵容", value: 3 },
        { label: "剧照赏析", value: 4 },
        { label: "媒体报道", value: 5 },
      ],
      swiperIndex: 0,
      memberId: [
        1740305576122032129,
        1740311607451492353,
        1740312116371562497,
        "1740305576122032129",
        "1740311607451492353",
        "1740312116371562497",
      ],
      actorId: [13, '13']
    };
  },
  computed: {
    teamMembers() {
      console.log(this.detail.team);
      console.log(
        this.detail.team.filter((item) => !this.memberId.includes(item.id))
      );
      return this.detail.team.filter(
        (item) => !this.memberId.includes(item.id)
      );
    },
    actorMembers() {
      return this.detail.actors.filter(
        (item) => !this.actorId.includes(item.id)
      );
    },
  },
  // created() {
  //   this.detail.team = this.detail.team.filter(
  //     (item) => !this.memberId.includes(item.id)
  //   );
  //   console.log(this.detail.team);
  // },
  methods: {
    handleTab(item) {
      document.querySelector(`#detail-${item.value}`).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    handleScroll(index) {
      this.$refs.pictureRef?.setActiveItem(index);
    },
    changeSwiper(index) {
      this.swiperIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/style/mixin.less");
.detail-info {
  text-align: left;
  border-bottom: 1/75rem solid #e4e6eb;
  padding: 40/75rem 30/75rem;

  .detail-body {
    .detail-box {
      & + .detail-box {
        margin-top: 40/75rem;
      }
      .content {
        margin-top: 20/75rem;
      }
    }
    #detail-1 {
      .content {
        font-size: 24/75rem;
        font-weight: 400;
        color: #606266;
        line-height: 30/75rem;
      }
      .video-box {
        margin: 60/75rem 0;
        text-align: center;
        #video {
          width: 690/75rem;
          height: 388/75rem;
        }
      }
    }
    .actor-list {
      display: grid;
      grid-gap: 22/75rem;
      grid-template-columns: repeat(4, 156/75rem);
    }
    .img-list {
      display: flex;
      width: 100%;
      overflow-x: auto;
      margin-top: 20/75rem;
      // &::-webkit-scrollbar {
      //   display: none;
      // }
      .img-item {
        flex-shrink: 0;
        width: 156/75rem;
        height: 94/75rem;
        border-radius: 8/75rem;
        border: 3/75rem solid transparent;
        box-sizing: border-box;
        & + .img-item {
          margin-left: 22/75rem;
        }
        &.active {
          border: 3/75rem solid var(--primary-color);
        }
      }
    }
  }

  ::v-deep(.el-carousel) {
    height: 340/75rem;
    .el-carousel__container {
      height: 340/75rem;
    }
  }
  ::v-deep(.el-carousel__arrow) {
    width: 44/75rem;
    height: 44/75rem;
    .background-image("/performance/rightjt.png");
    background-size: 100%;
    &.el-carousel__arrow--left {
      transform: translateY(-50%) rotate(180deg);
    }
    i {
      display: none;
    }
  }
}
</style>
