<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 15:13:26
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-26 20:14:52
 * @FilePath: \beijing-peoples-art-museum\src\views\mobile\performance\components\detail\VideoList.vue
 * @Description: 视频集锦
 *
-->

<template>
  <div v-if="list?.length" class="list-card video-list">
    <span class="video-title">视频集锦</span>
    <div
      v-for="item in list"
      :key="item.id"
      class="list-item"
      @click="handleDetail(item)"
    >
      <div class="left">
        <c-image :src="item.image" />
      </div>
      <div class="right">
        <div class="info">
          <p class="line-name">
            {{ item.title }}
          </p>
          <p class="line-info">
            <c-image
              :src="require('@/assets/img/performance/reloadtime@2x.png')"
            />
            <span>{{ dayjs(item.publishDate).format("YYYY-MM-DD") }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dayjs,
    };
  },
  methods: {
    // 查看详情
    handleDetail(item) {
      window.open(`#/culture/video/detail/${item.id}`, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/style/mixin.less");
.video-list {
  padding: 30/75rem;
  background-color: #ffffff;
  border-bottom: 1/75rem solid #e4e6eb;
  .video-title {
    font-size: 30/75rem;
    font-weight: 800;
    color: #212122;
    margin-bottom: 30/75rem;
  }
  .list-item {
    display: flex;
    margin-top: 20/75rem;
    .left {
      flex-shrink: 0;
      width: 136/75rem;
      height: 180/75rem;
      margin-right: 20/75rem;
      .img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 12/75rem;
      }
    }
    .right {
      box-sizing: border-box;
      height: 180/75rem;
      flex: 1;
      padding: 10/75rem 0 20/75rem;
      text-align: left;
      .info {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .line {
          &-name {
            // margin-top: 10/75rem;
            font-size: 26/75rem;
            font-weight: 800;
            color: #212122;
            .text-overflow(2);
          }
          &-tag {
            // margin-top: 12/75rem;
            display: flex;
            .tag {
              font-size: 12/75rem;
              font-weight: 400;
              color: #212122;
              background: #f8d39f;
              border-radius: 2/75rem;
              height: 20/75rem;
              line-height: 20/75rem;
              padding: 0 8/75rem;
              margin-right: 12/75rem;
            }
          }
          &-info {
            // margin-top: 12/75rem;
            display: flex;
            align-items: center;
            font-size: 20/75rem;
            font-weight: 400;
            color: #909399;
            .img {
              width: 20/75rem;
              height: 20/75rem;
              margin-right: 6/75rem;
            }
          }
        }
      }
    }
  }
}
</style>
