<!--
 * @Author: zhongxq
 * @Date: 2023-10-26 17:29:16
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-26 19:54:36
 * @FilePath: \beijing-peoples-art-museum\src\views\mobile\performance\components\detail\MediaItem.vue
 * @Description:
 *
-->
<template>
  <div class="list-card media-item" @click="handleDetail">
    <div class="left">
      <c-image :src="item.image" alt="" />
    </div>
    <div class="right">
      <div class="info">
        <p class="line-name">
          {{ item.title }}
        </p>
        <p class="line-from">
          来源：{{ item.source }}
          {{ dayjs(item.createTime).format("YYYY-MM-DD") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dayjs,
    };
  },
  methods: {
    handleDetail() {
      window.open(`#/ArtsInformation/detail?id=${this.item.id}`, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/style/mixin.less");
.media-item {
  display: flex;
  cursor: pointer;
  .left {
    flex-shrink: 0;
    width: 250/75rem;
    height: 158/75rem;
    margin-right: 20/75rem;
    .img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 12/75rem;
    }
  }
  .right {
    box-sizing: border-box;
    height: 158/75rem;
    flex: 1;
    text-align: left;
    .info {
      height: 100%;
      padding: 12/75rem 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      .line {
        &-name {
          font-size: 26/75rem;
          font-weight: 800;
          color: #212122;
          .text-overflow(2);
        }
        &-from {
          margin-top: 16/75rem;
          font-size: 20/75rem;
          color: #909399;
          .text-overflow(2);
        }
      }
    }
  }
}
</style>
