<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 15:57:01
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-27 17:19:47
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\performance\components\mobile\detail\Actor.vue
 * @Description: 演员
 *
-->
<template>
  <div class="actor-box" @click="handleDetail">
    <div class="info-1 overflow-ellipsis">
      {{ type === "actor" ? "饰：" : "" }}{{ item.role }}
    </div>
    <c-image class="people-img" :src="item.image" error-type="artist" />
    <div class="info-2">{{ item.name }}</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    type: String,
  },
  data() {
    return {};
  },
  methods: {
    handleDetail() {
      if (this.item.typeId == 73) return false;
      else {
        window.open(`#/PeopleEntertainers/detail?id=${this.item.id}`, "_blank");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.actor-box {
  width: 156/75rem;
  overflow: hidden;
  font-size: 20/75rem;
  font-weight: 400;
  line-height: 24/75rem;
  margin-bottom: 15/75rem;
  cursor: pointer;
  .info-1 {
    // min-height: 25px;
    color: #909399;
  }
  .people-img {
    width: 156/75rem;
    height: 204/75rem;
    border-radius: 8/75rem;
    margin: 12/75rem 0;
  }
  .info-2 {
    font-size: 24/75rem;
    line-height: 24/75rem;
    color: #212122;
  }
}
</style>
