<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 13:32:13
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-29 18:47:11
 * @FilePath: \beijing-peoples-art-museum\src\views\mobile\performance\components\detail\MainInfo.vue
 * @Description: 剧目信息
 *
-->
<template>
  <div class="main-info">
    <div class="top-info">
      <div class="left">
        <c-image :src="detail.image" alt="" />
        <span v-if="detail.tag" class="tag">{{
          detail.tag?.split(",")[0]
        }}</span>
      </div>
      <div class="right">
        <div class="info">
          <p class="line-name overflow-ellipsis">
            {{ detail.repertoireName }}
          </p>
          <p class="line-tag">
            <span class="tag">{{ detail.performerName }}</span>
          </p>
          <p class="line-info">
            <span>演出剧场：{{ detail.theatresName }}</span>
          </p>
          <p class="line-info mt-24">
            <span>编剧：{{ writer }}</span>
          </p>
          <p class="line-info mt-24">
            <span>背景年代：{{ detail.centuryName }}</span>
          </p>
          <p class="line-info mt-24">
            <span>导演：{{ director }}</span>
          </p>
        </div>
      </div>
    </div>
    <div v-if="!hideTicket" class="bottom-info">
      <p class="line-info">演出日期：{{ detail.showTime }}</p>
      <p class="line-info mt-24">
        <span class="time">时间说明：{{ detail.timeDesc }}</span>
      </p>
      <p class="line-hr"></p>
      <p class="line-info">
        票&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;价：<span
          class="price"
          >{{ detail.price }}</span
        >
      </p>
    </div>
    <div v-if="!hideTicket" class="btn-box">
      <button @click="handleShowDialog(1)">剧目评分</button>
      <button v-if="detail.actors?.length" @click="handleShowDialog(2)">
        演员评分
      </button>
      <button v-if="detail.canbuy" class="btn-buy" @click="handleBuy">
        购票
      </button>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="80%"
      top="20vh"
      append-to-body
      destroy-on-close
      custom-class="score-dialog"
    >
      <div v-if="type === 1" class="single-score">
        <p class="title">请点击星星评分</p>
        <el-rate
          v-model="value"
          :max="10"
          show-score
          score-template="{value}分"
        ></el-rate>
      </div>
      <div v-else class="multi-score">
        <div v-for="item in detail.actors" :key="item.id" class="actor-score">
          <div class="left">
            <c-image :src="item.image" />
            <div>
              <p>{{ item.name }}</p>
              <p>饰：{{ item.role }}</p>
            </div>
          </div>
          <div class="right">
            <el-rate
              v-model="valueObj[`${item.id}`]"
              :max="5"
              allow-half
            ></el-rate>
            <span>{{ valueObj[`${item.id}`] * 2 }}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { repertoireScore, entertainerScore } from "@/service/performance";
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      title: "剧目打分",
      dialogVisible: false,
      value: 0,
      type: 1,
      loading: false,
      valueObj: {},
    };
  },
  computed: {
    // 从剧目典藏进入
    fromDc() {
      return this.$route.query.from === "dc";
    },
    hideTicket() {
      return this.fromDc;
    },
    writer() {
      let arr = this.detail.team || [];
      arr = [
        ...new Set(
          arr.filter((item) => item.role === "编剧").map((item) => item.name)
        ),
      ];
      return arr.join(",");
    },
    director() {
      let arr = this.detail.team || [];
      arr = [
        ...new Set(
          arr.filter((item) => item.role === "导演").map((item) => item.name)
        ),
      ];
      return arr.join(",");
    },
  },
  methods: {
    handleBuy() {
      window.open(process.env.VUE_APP_TICKET_URL, "_blank");
    },
    handleShowDialog(type) {
      this.type = type;
      this.title = type === 1 ? "剧目打分" : "演员打分";
      this.value = 0;
      this.valueObj = {};
      this.detail.actors?.map((item) => {
        this.$set(this.valueObj, `${item.id}`, 0);
      });
      this.dialogVisible = true;
    },
    async handleSubmit() {
      if (this.type === 1) {
        if (!this.value) {
          this.$message.warning("请点击星星评分");
          return;
        }
        this.loading = true;
        try {
          await repertoireScore({ id: this.detail.id, score: this.value });
          this.$message.success("评分成功");
          this.dialogVisible = false;
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      } else {
        const keys = Object.keys(this.valueObj).filter(
          (key) => this.valueObj[key] > 0
        );
        if (keys.length === 0) {
          this.$message.warning("请点击星星评分");
          return;
        }
        this.loading = true;
        let apis = [];
        keys.forEach((key) => {
          const api = entertainerScore({
            id: key,
            score: this.valueObj[key] * 2,
          });
          apis.push(api);
        });
        try {
          await Promise.all(apis);
          this.$message.success("评分成功");
          this.dialogVisible = false;
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main-info {
  box-sizing: border-box;
  margin: 30/75rem 30/75rem 0;
  position: relative;
  .top-info {
    display: flex;
  }
  .mt-24 {
    margin-top: 24/75rem;
  }
  .left {
    flex-shrink: 0;
    width: 212/75rem;
    height: 312/75rem;
    margin-right: 30/75rem;
    position: relative;
    border-radius: 12/75rem;
    .img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 12/75rem;
    }
    .tag {
      position: absolute;
      left: 10/75rem;
      top: 10/75rem;
      height: 30/75rem;
      background: #bf805f;
      border-radius: 2/75rem;
      line-height: 30/75rem;
      font-size: 20/75rem;
      color: #ffffff;
      padding: 0 8/75rem;
    }
  }
  .right {
    flex: 1;
    .info {
      text-align: left;
      .line {
        &-name {
          font-size: 34/75rem;
          font-weight: 800;
          color: #212122;
        }
        &-tag {
          margin: 16/75rem 0 40/75rem;
          display: flex;
          .tag {
            font-size: 20/75rem;
            font-weight: 400;
            color: #212122;
            background: #f8d39f;
            border-radius: 2/75rem;
            height: 28/75rem;
            line-height: 28/75rem;
            padding: 0 8/75rem;
            margin-right: 12/75rem;
          }
        }
        &-info {
          display: flex;
          font-size: 24/75rem;
          line-height: 24/75rem;
          color: #212122;
          span {
            display: inline-block;
            font-weight: 400;
            color: #909399;
            & + span {
              margin-left: 10/75rem;
            }
          }
        }
        &-hr {
          margin: 20/75rem 0;
          width: 100%;
          height: 1/75rem;
          background: #f4f4f4;
        }
      }
    }
  }
  .bottom-info {
    background: #f8f8fa;
    border-radius: 12/75rem;
    padding: 30/75rem;
    margin-top: 30/75rem;
    .line-info {
      display: flex;
      font-size: 24/75rem;
      line-height: 26/75rem;
      color: #212122;
      span {
        display: inline-block;
        font-weight: 400;
        color: #909399;
        & + span {
          margin-left: 10/75rem;
        }
      }
      .time {
        width: auto;
      }
      .price {
        color: var(--primary-color);
        font-weight: bold;
      }
    }
    .line-hr {
      margin: 20/75rem 0;
      width: 100%;
      height: 1/75rem;
      background: #f4f4f4;
    }
  }

  .btn-box {
    display: flex;
    margin-top: 30/75rem;
    justify-content: space-between;
    button {
      flex: 1;
      background: rgba(249, 104, 13, 0.1);
      border-radius: 4/75rem;
      width: 214/75rem;
      height: 64/75rem;
      background: rgba(249, 104, 13, 0.1);
      border-radius: 4/75rem;
      border: none;
      outline: none;
      font-size: 26/75rem;
      color: #212122;
      cursor: pointer;
      & + button {
        margin-left: 24/75rem;
      }
    }
    .btn-buy {
      background-color: var(--primary-color);
      color: #ffffff;
    }
  }
}
.single-score {
  .title {
    font-size: 26/75rem;
    margin-bottom: 10/75rem;
  }
}
.multi-score {
  max-height: 50vh;
  overflow-y: auto;
  text-align: left;
  .actor-score {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & + .actor-score {
      margin-top: 10/75rem;
    }
    .left {
      display: flex;
      align-items: center;
      .img {
        width: 80/75rem;
        height: 80/75rem;
        margin-right: 10/75rem;
        border-radius: 2/75rem;
        flex-shrink: 0;
      }
      p {
        margin: 5/75rem 0;
      }
    }
    .right {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        width: 30/75rem;
        flex-shrink: 0;
      }
    }
  }
}
</style>
<style lang="less">
.score-dialog {
  .el-dialog__body,
  .el-dialog__title,
  .el-dialog__headerbtn,
  .el-button,
  .el-rate__icon,
  .el-rate__text {
    font-size: 24/75rem;
  }
  .el-rate {
    height: 26/75rem;
  }
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 20/75rem;
  }
}
</style>
