<!--
 * @Author: zhongxq
 * @Date: 2023-10-19 22:48:28
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-29 18:39:52
 * @FilePath: \beijing-peoples-art-museum\src\views\mobile\performance\detail.vue
 * @Description: 剧目信息
 *
-->
<template>
  <div class="fixed-page-mobile performance-detail-mobile">
    <div class="container" :key="id">
      <section class="panel-left">
        <MainInfo :detail="detail" />
        <DetailInfo :detail="detail" />
      </section>
      <aside class="panel-right">
        <VideoList :list="detail.videos" />
        <TelCom v-if="!fromDc" />
        <CinemaCom v-if="!fromDc" />
      </aside>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getRepertoireDetail, repertoireHit } from "@/service/performance";
import MainInfo from "./components/detail/MainInfo.vue";
import DetailInfo from "./components/detail/DetailInfo.vue";
import VideoList from "./components/detail/VideoList.vue";
import TelCom from "./components/TelCom.vue";
import CinemaCom from "./components/CinemaCom.vue";
export default {
  components: {
    MainInfo,
    DetailInfo,
    VideoList,
    TelCom,
    CinemaCom,
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {
        actors: [],
        team: [],
        newsLinks: [],
        pictures: [],
        videos: [],
      },
    };
  },
  created() {
    this.getData();
  },
  watch: {
    $route() {
      if (this.$route.params.id !== this.id) {
        this.id = this.$route.params.id;
        this.getData();
      }
    },
  },
  computed: {
    // 从剧目典藏进入
    fromDc() {
      return this.$route.query.from === "dc";
    },
  },
  methods: {
    async getData() {
      try {
        const res = await getRepertoireDetail(this.id);
        const data = res.data || {};
        data.actors = JSON.parse(data.actors || "[]");
        data.team = JSON.parse(data.team || "[]");
        data.newsLinks = JSON.parse(data.newsLinks || "[]");
        data.pictures = JSON.parse(data.pictures || "[]");
        data.videos = JSON.parse(data.videos || "[]");
        // 演员
        const actors = [];
        data.actors?.forEach((item) => {
          if (item.list) {
            item.list.forEach((it) => {
              const newItem = { ...item, ...it };
              delete newItem.list;
              actors.push(newItem);
            });
          }
        });
        data.actors = actors;

        // 团队
        const team = [];
        data.team?.forEach((item) => {
          if (item.list) {
            item.list.forEach((it) => {
              const newItem = { ...item, ...it };
              delete newItem.list;
              team.push(newItem);
            });
          }
        });
        data.team = team;

        // 是否在售票日期内
        data.canbuy = false;
        if (data.sell1 && data.sell2) {
          const start = dayjs(data.sell1).startOf("day").valueOf();
          const end = dayjs(data.sell2).endOf("day").valueOf();
          const now = dayjs().valueOf();
          if (now > start && now < end) {
            data.canbuy = true;
          }
        }

        this.detail = data;
        this.click();
      } catch (e) {
        console.log(e);
      }
    },
    click() {
      this.detail.id && repertoireHit(this.detail.id);
    },
  },
};
</script>

<style lang="less">
.performance-detail-mobile {
  p {
    margin: 0;
  }
  .container {
    width: 100%;
  }
  .list-card + .list-card {
    margin-top: 30/75rem;
  }
}
</style>
